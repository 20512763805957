<template>
  <div class="text-container">
    <div class="text_item" ref="target0">
      <div class="border"></div>
      <div class="title">二輪用品</div>
      <div
        style="
          border: 0.2px solid #fff;
          width: 100px;
          margin-bottom: 4px;
          margin-top: 4px;
        "
      ></div>
      <div style="border: 0.2px solid #fff; width: 100px; margin-bottom: 20px"></div>
      <ul class="grid">
        <li
          class="grid_item"
          v-for="(item, index) in gridFirst"
          :key="index"
          @click="href(item)"
        >
          <div class="img_box">
            <img :src="item.img" />
          </div>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>

    <div class="text_item" id="target1">
      <div class="border"></div>
      <div style="display: flex" class="img-container">
        <img src="@/assets/home3.jpg" alt="" style="margin-left: 0" />
        <img src="@/assets/home4.png" alt="" />
        <img src="@/assets/brand3.jpg" alt="" style="margin-right: 0" />
      </div>
    </div>

    <div class="text_item" ref="target2">
      <div class="border"></div>
      <div class="title">四輪用品</div>
      <div
        style="
          border: 0.2px solid #fff;
          width: 100px;
          margin-bottom: 4px;
          margin-top: 4px;
        "
      ></div>
      <div style="border: 0.2px solid #fff; width: 100px; margin-bottom: 20px"></div>
      <p style="margin-bottom: 20px">ただいまメンテナンス中</p>
      <p style="margin-bottom: 20px">
        日頃より三恒一樹株式会社をご利用いただきありがとうございます。
        お知らせいたします。
      </p>
    </div>
    <div class="text_item">
      <div class="border" style="margin: 50px 0"></div>
    </div>
    <div class="botImg">
      <img src="@/assets/between-logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileBrand",
  data() {
    return {
      gridFirst: [
        {
          name: "ヘルメット",
          img: require("../../assets/SHM.png"),
          href: "http://dinmarket.jp/brand/shm-lot-500/",
        },
        {
          img: require("../../assets/GREASER.png"),
          name: "ヘルメット",
          href: "http://dinmarket.jp/brand/60s-plain/",
        },
        {
          name: "ヘルメット",
          img: require("../../assets/72JAM.png"),
          href: "https://www.72jam.com/",
        },
        {
          name: "ヘルメット",
          img: require("../../assets/GMG1.png"),
          href: "http://dinmarket.jp/brand/customgt-750/",
        },
        {
          name: "ヘルメット",
          img: require("../../assets/shm-helmet.png"),
          href:
            "http://dinmarket.jp/brand/%E3%82%AC%E3%83%B3%E3%82%B7%E3%83%83%E3%83%97%E3%82%B0%E3%83%AC%E3%83%BCx%E3%83%9C%E3%83%B3%E3%83%8D%E3%83%93%E3%83%AB%E3%82%BD%E3%83%AB%E3%83%88%E3%83%87%E3%83%8B%E3%83%A0/",
        },
        {
          name: "ヘルメット",
          img: require("../../assets/buco.png"),
          href: "",
        },
        {
          name: "バッグ",
          img: require("../../assets/cuslom.png"),
          href: "http://dinmarket.jp/brand/canvassddlebag/",
        },
        {
          name: "グローブ",
          img: require("../../assets/GMG.png"),
          href: "http://dinmarket.jp/brand/gmg-07/",
        },
        {
          name: "バッグ",
          img: require("../../assets/shm-genuine.png"),
          href: "http://dinmarket.jp/brand/shmleathershoulderbag-2/",
        },
      ],
      target: "",
    };
  },
  methods: {
    href(item) {
      if (item.href.length) {
        window.open(item.href);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.text-container {
  margin: 30px auto;
  .text_item {
    text-align: left;
    .border {
      border-top: 1px dashed #3b3b3b;
      /* 设置顶部边框为点线 */
      border-radius: 0.5px;
      /* 圆角边框 */
      background-image: linear-gradient(to right, #fff 50%, #3b3b3b 50%);
      /* 创建点线效果的背景图像 */
      background-size: 10px 10px;
      /* 背景图像大小 */
      height: 1px;
      /* 线的高度 */
      width: 100%;
      /* 线的宽度 */
      text-align: center;
      /* 文本居中 */
      background-repeat: repeat-x;
      /* 背景图像横向重复 */
      margin-bottom: 20px;
    }
    .title {
      width: 200px;
      text-align: left;
      font-size: 16px;
      letter-spacing: 2px;
    }
    p {
      letter-spacing: 2px;
      font-size: 14px;
      line-height: 20px;
    }
    .grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .grid_item {
        width: 33%;
        display: flex;
        height: 100px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;
        cursor: pointer;
        .img_box {
          height: 85%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 60%;
          }
        }
      }
    }

    .img-container {
      margin: 30px 0;
      img {
        margin: auto;
        width: 32%;
        height: 80px;
        box-shadow: 1px 0 2px 2px rgb(0, 0, 0), 3px 2px 3px 2px rgb(255, 255, 255, 0.7);
      }
    }
  }
}
.botImg {
  width: 100%;
  margin-top: 10px;
  img {
    display: block;
    width: 100%;
    box-shadow: 1px 0 3px 4px rgb(0, 0, 0), 3px 2px 3px 4px rgb(255, 255, 255, 0.7);
  }
}
</style>
